import React from "react"
import { fetchEditorialPageData } from "../contents/editorialPage/fetch"
import { SeoContents } from "../features/seo/components/PageSEO"
import { wrapPage } from "../aggregators/page"
import { DefaultLayout } from "../layouts/Default/DefaultLayout"
import EditorialContents from "../containers/editorialPage/components/EditorialContents"
import { homepageQuery } from "../contents/editorialPage/queries"
import { WrapPage } from "../root"
import DefaultOGTags from "../features/seo/components/DefaultOGTags"

interface Props {
  contents: any
  languageId: string
  slug: string
  rootData: any
}

export default function HomePage({ contents: data, rootData }: Props) {
  // const { data } = usePreviewSubscription(homepageQuery, {
  //   params: { locale: languageId, slug },
  //   initialData: contents,
  //   enabled: isPreviewSession(),
  // })
  //

  return (
    <WrapPage rootData={rootData}>
      <DefaultLayout home>
        <EditorialContents contents={data.contents} />
        <DefaultOGTags />
        <SeoContents page={data} />
      </DefaultLayout>
    </WrapPage>
  )
}

export async function getStaticProps({ locale }: any) {
  return await wrapPage({
    locale,
    fetchProps: async (languageId: string) => {
      const slug = "home"
      const contents = await fetchEditorialPageData({
        query: homepageQuery,
        languageId,
        slug,
      })
      return {
        slug,
        contents,
      }
    },
    other: {
      revalidate: 10,
    },
  })
}
