import React from "react"
import { COVER_IMAGE } from "../data/meta"

interface Props {
  title: string
  type: string
  description: string
  url?: string
  siteName?: string
  image?: string
}
export const OpenGraphSEO = ({
  title,
  type,
  description,
  url,
  siteName,
  image,
}: Props) => {
  return (
    <>
      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:description" content={description} />
      {url && <meta property="og:url" content={url} />}
      {siteName && <meta property="og:site_name" content={siteName} />}
      <meta property="og:image" content={image ?? COVER_IMAGE} />
    </>
  )
}

/* <meta property="og:type" content="business.business" />
        <meta property="og:title" content="Modus Profumerie" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://www.modusprofumerie.com" />
        <meta
          property="og:image"
          content="https://www.modusprofumerie.com/images/modus-profumerie-torino-facebook.jpg"
        />
        <meta property="og:site_name" content="Modus Profumerie" /> */
