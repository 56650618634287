import { SEOContext } from "@punks/core"
import React from "react"
import { OpenGraphSEO } from "./OpenGraphSEO"
import { Seo } from "./SEO"
import { TwitterSEO } from "./TwitterSEO"

interface Props {
  page: any
}

export const SeoContents = ({ page }: Props) => {
  const metadata = React.useContext(SEOContext)
  const metaDescription = page?.data?.seo?.description ?? metadata.description
  const robots = [
    page?.data?.seo?.noIndex ? "noindex" : "index",
    page?.data?.seo?.noFollow ? "nofollow" : "follow",
  ].join(", ")
  const title = page?.data?.seo?.title
    ? `${page.data.seo.title} | ${metadata.title}`
    : ""
  return (
    <Seo description={metaDescription} title={title} robots={robots}>
      <OpenGraphSEO
        description={metaDescription}
        title={title ?? metadata.title}
        type="website"
      />
      <TwitterSEO
        description={metaDescription}
        title={title ?? metadata.title}
        card="summary_large_image"
      />
    </Seo>
  )
}
