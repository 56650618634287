import Head from "next/head"
import React from "react"
import { COVER_IMAGE } from "../data/meta"

const DefaultOGTags = () => {
  return (
    <Head>
      <meta property="og:type" content="business.business" />
      <meta property="og:title" content="Modus Profumerie" />
      <meta property="og:description" content="" />
      <meta property="og:url" content="https://www.modusprofumerie.it" />
      <meta property="og:image" content={COVER_IMAGE} />
      <meta property="og:site_name" content="Modus Profumerie" />
    </Head>
  )
}

export default DefaultOGTags
