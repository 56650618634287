import React from "react"

interface Props {
  title: string
  card: "summary" | "summary_large_image" | "app" | "player"
  description?: string
  username?: string
  image?: string
}
export const TwitterSEO = ({
  title,
  card,
  username,
  description,
  image,
}: Props) => {
  return (
    <>
      <meta name="twitter:card" content={card} />
      <meta name="twitter:title" content={title} />
      {username && <meta name="twitter:site" content={username} />}
      {description && <meta name="twitter:description" content={username} />}
      {image && <meta name="twitter:image" content={username} />}
    </>
  )
}
